.slider-wrapper {
    max-height: 400px;
}

.scroll-container {
    scroll-behavior: smooth;
    transition: transform 0.2s ease-in-out;
    transform: translateX(0);
  }
  
  .scroll-container::-webkit-scrollbar {
    display: none;
  }
