@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    user-select: none;

    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 1.25rem;
        background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #a0a0a5;
    }
}

html,
body,
#root {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-flow: column nowrap;
    min-height: 25rem;
    min-width: 20rem;
    background-color: #F9F9F9;
    color: #616161 !important;
    font-family: "Roboto", sans-serif;
}



#root:not(:empty)+.splash {
    display: none;
    opacity: 1;
    z-index: 100;
}